import { CheckIcon, DocumentTextIcon, UploadIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useRef, useState, DragEvent, useEffect } from "react";
import { SignatureRequestContext } from "../contexts";
import { useTranslation } from "react-i18next";

const CHUNK_SIZE = 64 * 1024 * 1024;

function VerifyUploadModal(props: { visible: boolean, closeModal: () => void }) {
  const { branding } = useContext(SignatureRequestContext);
  const { t } = useTranslation();

  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const [fileHash, setFileHash] = useState<string>("");
  const [fileHashExists, setFileHashMatches] = useState<boolean | null>(null);

  const uploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!file)
      return;

    // https://stackoverflow.com/questions/768268/how-to-calculate-md5-hash-of-a-file-using-javascript/63287199#63287199

    // @ts-ignore
    const hashwasm = window.hashwasm;

    let hasher: any = null;
    const fileReader = new FileReader();

    function hashChunk(chunk: Blob): Promise<void> {
      return new Promise((resolve, reject) => {
        fileReader.onload = async (e: any) => {

          if (!hasher)
            hasher = await hashwasm.md5();

          const view = new Uint8Array(e.target.result);
          hasher.update(view);

          resolve();
        };

        fileReader.readAsArrayBuffer(chunk);
      });
    }

    const readFile = async (file: File) => {
      if (hasher) {
        hasher.init();
      } else {
        hasher = await hashwasm.createMD5();
      }

      const chunkNumber = Math.floor(file.size / CHUNK_SIZE);

      for (let i = 0; i <= chunkNumber; i++) {
        const chunk = file.slice(
          CHUNK_SIZE * i,
          Math.min(CHUNK_SIZE * (i + 1), file.size)
        );
        await hashChunk(chunk);
      }

      const hash = hasher.digest();
      return Promise.resolve(hash);
    };

    readFile(file)
      .then(hash => { setFileHash(hash); checkWithServer(hash) });

  }, [file]);


  function checkWithServer(hash: string) {
    const abortController = new AbortController();

    setFileHashMatches(null);

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/verify/`, {
      method: "POST",
      body: JSON.stringify({ hash }),
      signal: abortController.signal,
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.ok ? res.json() : null)
      .then(json => setFileHashMatches(json?.["exists"] ?? false))
      .catch(console.error);
  }

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    setFile(file);
  };

  if (!props.visible)
    return null;

  return (
    <div className="flex absolute top-0 left-0 w-full h-full z-50 backdrop-filter backdrop-blur-sm">
      <div className="flex flex-col gap-2 relative mt-auto lg:m-auto p-8 w-full lg:w-[600px] bg-white text-black lg:rounded shadow-lg" style={{ backgroundColor: branding?.colour }}>
        <XIcon className="w-4 h-4 absolute top-4 right-4 cursor-pointer" onClick={props.closeModal} />
        <div className="relative text-lg font-semibold">
          {t("Upload and Verify")}
        </div>
        <div className="relative mx-auto font-semibold text-sm text-darkgray">
          {t("Upload a document signed with Portant and we will compare it's contents to ensure it matches the original version we have in our system.")}
        </div>
        <div
          className='w-full h-full min-h-[250px] rounded-lg border border-dashed border-gray flex flex-col gap-4 items-center justify-center cursor-pointer hover:bg-gray-50 transition-colors'
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => uploadRef.current?.click()}
        >
          <div className='relative w-16 h-16'>
            <DocumentTextIcon className='w-16 h-16 text-gray' />
            <span className='bg-blue absolute right-0 bottom-0 rounded-full p-1'>
              <UploadIcon className='text-white w-4 h-4' />
            </span>
          </div>
          <p className='text-xs font-bold'>
            {isDragging
              ? <>{t("Drop your files here...")}</>
              : <>{t("Drag and drop file here or")} <u>{t("select file")}</u></>
            }
          </p>
        </div>
        <div className='flex justify-between items-center mt-2'>
          <p className='text-xs text-gray-400 font-semibold'>{t("Supported formats: PDF")}</p>
        </div>
        {file &&
          <div className='w-full flex flex-col gap-4 mt-2 h-20'>
            <div className="flex items-center gap-2">
              <img src="https://static.portant.co/pdf-icon.svg" alt='PDF' className='w-8 h-8' />
              <div className="flex flex-col overflow-hidden">
                <div className='font-semibold text-darkgray font-gilroy text-sm truncate whitespace-nowrap'>{file.name}</div>
                <div className="font-semibold text-gray font-gilroy text-xs">{fileHash}</div>
              </div>
            </div>
            {fileHashExists &&
              <div className="border-green text-green text-sm bg-green/10 h-full flex items-center gap-1 justify-center rounded font-semibold font-gilroy">
                <CheckIcon className='w-6 h-6 text-green' />
                <div className="mt-1">
                  {t("This document matches the original version within our system")}
                </div>
              </div>
            }
            {(!fileHashExists && fileHashExists !== null) &&
              <div className="border-red text-red text-sm bg-red/10 h-full flex items-center gap-1 justify-center rounded font-semibold font-gilroy">
                <XIcon className='w-6 h-6 text-red' />
                <div className="mt-1">
                  {t("This document does not match any version within our system")}
                </div>
              </div>
            }
          </div>
        }
        <input
          className='h-0 w-0 invisible absolute pointer-events-none'
          type="file"
          ref={uploadRef}
          accept='application/pdf'
          onChange={e => setFile(e.currentTarget.files?.[0] ?? null)}
        />
      </div>
    </div>
  );
}

export default VerifyUploadModal;
