import { XIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalStateContext, ModalContext, SignatureRequestContext } from "../contexts";
import { formatDate, formatTime } from "../formatting";
import { GlobalStateActionType } from "../reducers";
import { luminance } from "../utils";
import LargeCheckbox from "./LargeCheckbox";
;

type Props = {
  visible: boolean,
};

function AgreementModal(props: Props) {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const { closeModal } = useContext(ModalContext);
  const { dispatch } = useContext(GlobalStateContext);
  const { t } = useTranslation();

  const { branding, formatting } = useContext(SignatureRequestContext);

  function handleInsert() {
    const now = new Date();

    const timeString = formatTime(now, formatting?.dateTimeFormat ?? "hh:mmaa");
    const dateString = (formatDate(now, formatting?.dateFormat ?? "MM/DD/YYYY") + " " + timeString).trim();

    dispatch({
      type: GlobalStateActionType.INSERT_DATE,
      payload: {
        signatureDateString: dateString
      }
    });

    closeModal();
  }

  if (!props.visible)
    return null;

  return (
    <div className="flex absolute top-0 left-0 w-full h-full z-50 backdrop-filter backdrop-brightness-[30%]">
      <div className="flex flex-col relative m-auto p-5 lg:p-8 mx-5 lg:mx-auto w-full max-w-md lg:max-w-2xl bg-white rounded-3xl shadow-lg overflow-hidden">
        <XIcon className="w-4 h-4 absolute top-7 right-7 text-gray hover:text-red cursor-pointer" onClick={closeModal} />
        <h2 className="font-title font-semibold text-xl">
          {t("Confirmation")}
        </h2>
        <div className="flex flex-col justify-between items-center">
          <div className="flex gap-2 items-center w-full my-8 rounded-lg bg-gray shadow p-4">
            <LargeCheckbox checked={acceptTerms} onChange={() => setAcceptTerms(prev => !prev)} />
            <p className="text-darkgray text-lg font-semibold mx-auto cursor-pointer" onClick={() => setAcceptTerms(prev => !prev)}>{t("I agree with the content stated in this document")}</p>
          </div>
          <button className="btn btn-primary w-full rounded-full mt-4" onClick={handleInsert} style={{ backgroundColor: branding?.colour, color: luminance(branding?.colour ?? "#000000") >= 0.5 ? "black" : "white" }} disabled={!acceptTerms}>
            {t("Confirm")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AgreementModal;
