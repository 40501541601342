import { useTranslation } from "react-i18next";
import { luminance } from "../utils";
import { Dispatch, useContext, useEffect, useState } from "react";
import { GlobalStateContext, SignatureRequestContext } from "../contexts";
import LanguagePicker from "./LanguagePicker";

type Props = {
  currentDocumentIndex: number;
  setShowSignatureModal: Dispatch<React.SetStateAction<boolean>>;
  isPreview: boolean;
  hasSignatureField: boolean;
  handleClickEdit: () => void;
  handleClickAgree: () => void;
}


const MainSideCard = (props: Props) => {
  const { t } = useTranslation();
  const { currentDocumentIndex, setShowSignatureModal, isPreview, handleClickEdit, handleClickAgree } = props;
  const { state: globalState } = useContext(GlobalStateContext);
  const signatureRequest = useContext(SignatureRequestContext);
  const [showIncompleteWarning, setShowIncompleteWarning] = useState(false);
  const signableDocument = signatureRequest?.signableDocuments[currentDocumentIndex] ?? null;
  const branding = signatureRequest?.branding;

  const nDocuments = signatureRequest?.signableDocuments.length ?? 0;
  const isLastDocument = (currentDocumentIndex >= (nDocuments - 1));

  useEffect(() => {
    if (showIncompleteWarning) {
      setShowIncompleteWarning(false);
      signableDocument.fieldRegions.forEach(region => {
        const element = document.getElementById(region.id);
        if (element) {
          element.style.outline = "none";
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState, signableDocument.fieldRegions]);

  const onClickAgree = () => {
    if (!signatureRequest.allFieldsRequired) {
      return handleClickAgree();
    } else {
      const incompleteFields = signableDocument.fieldRegions.filter(region => !["SIGNATURE", "DATE"].includes(region.type) && (!globalState?.fieldValues[region.id] || globalState?.fieldValues[region.id] === "") && signatureRequest.recipientNumber === region.recipient);
      if (incompleteFields.length === 0)
        return handleClickAgree();

      incompleteFields.forEach((region, index) => {
        const element = document.getElementById(region.id);
        if (element) {
          element.style.outline = "2px solid yellow";
          if (index === 0) {
            element.scrollIntoView({ behavior: "auto", block: "center" });
          }
        }
      })
      incompleteFields[0]?.id && document.getElementById(incompleteFields[0].id)?.scrollIntoView({ behavior: "smooth", block: "center" });
      setShowIncompleteWarning(true);
    }
  }

  return (
    <div className="fixed bottom-0 left-0 w-full lg:static flex flex-col z-10 lg:max-w-[356px] p-6 lg:p-8 lg:rounded-3xl h-min bg-blue text-white shadow-md" style={{ ...(branding?.colour && { backgroundColor: branding?.colour, color: luminance(branding?.colour ?? "#000000") >= 0.5 ? "black" : "white" }) }}>
      <div className="flex flex-col items-center justify-between w-full max-w-xl mx-auto">
        {branding?.logo && <div className="mb-4"> <img src={branding?.logo} className="h-12" alt="Brand Logo" /> </div>}

        <div className="text-left w-full font-semibold font-title text-lg lg:text-xl leading-6 lg:leading-8">
          {signableDocument.documentName}
          {nDocuments > 1 &&
            <span className="inline-block ml-2 text-white/80 hover:text-white font-semibold">({currentDocumentIndex + 1} {t("of")} {nDocuments})</span>
          }
        </div>

        {globalState.status === "INITIAL" && <>
          {signatureRequest.recipientNumber >= 1 &&
            <button className="btn btn-secondary mt-5 mb-4 lg:mt-6 py-3 lg:py-2.5 w-full rounded-full text-sm lg:text-base font-inter font-semibold" onClick={() => setShowSignatureModal(true)} disabled={isPreview}>
              {currentDocumentIndex === 0 ? (props.hasSignatureField ? t('Sign now') : t('Agree')) : t("Continue")}
            </button>
          }

          <div className="flex items-center gap-2 font-inter font-semibold text-xs leading-[18px] lg:leading-5 lg:text-sm cursor-pointer flex-shrink-0" onClick={() => window.open("https://www.portant.co", "_blank")}>
            {t("Powered by")}
            {luminance(branding?.colour ?? "#000000") >= 0.5
              ? <img src="https://static.portant.co/logo-full.png" className="h-5" alt="Portant Logo" />
              : <img src="https://static.portant.co/logo-full-white.png" className="h-5" alt="Portant Logo" />
            }
          </div>
        </>}
        {(globalState.status === "SIGNED") &&
          <>
            {isLastDocument &&
              <div className="text-sm flex-shrink mt-4">
                {t("I agree to be legally bound by the document and the")} <a className="underline" href="https://www.portant.co/terms-and-conditions" target="_blank" rel="noreferrer">{t("Portant Terms of Service")}</a>. {t("Click on 'I Agree' to submit this document.")} <button className="inline text-sm underline" onClick={handleClickEdit}>{t("Edit")}</button>
              </div>
            }
            <div className="flex-col items-center w-full">
              <button className="btn btn-secondary mb-4 mt-4 w-full rounded-full" onClick={onClickAgree} disabled={showIncompleteWarning}>
                {isLastDocument ? t("I Agree") : t("Review Next Document")}
              </button>

              {showIncompleteWarning && <p className="text-white bg-yellow p-2 rounded border-white border my-4">{t("Please complete every field to be able to submit the document.")}</p>}
              {!branding &&
                <div className="flex items-center font-inter font-semibold justify-center lg:text-sm leading-[18px] lg:leading-5 text-white gap-1 text-xs cursor-pointer" onClick={() => window.open("https://www.portant.co", "_blank")}>
                  {t("Powered by")}
                  <img src="https://static.portant.co/logo-full-white.png" width={80} alt="Portant Logo" />
                </div>
              }

            </div>
          </>
        }
        {globalState.status === "SUBMITTING" && <>
          <div className="mx-auto mt-4">
            <span className="font-semibold">{t("Submitting...")}</span>
          </div>
        </>}

        {(globalState.status !== "SUBMITTING") && <LanguagePicker className="mt-4" />}
      </div>
    </div>
  );
}

export default MainSideCard;