import { useState } from "react";
import { useTranslation } from "react-i18next";
import VerifyUploadModal from "./VerifyUploadModal";

function AppLanding() {
  const { t } = useTranslation();

  const [showVerifyUploadModal, setShowVerifyUploadModal] = useState<boolean>(false);

  return (
    <div className="fixed flex flex-col w-full items-center justify-center h-full bg-gray">
      <div className="flex flex-col items-center max-w-[800px] text-center gap-2">
        <img src="https://static.portant.co/logo-full-blue.png" className="mb-4 mx-auto" width={160} alt="Portant Logo" />
        <div className="mb-2 font-semibold font-title">
          {t("Generate contracts and request signatures easily with Portant")}
        </div>
        <a href="https://www.portant.co" className="btn btn-primary w-60">
          {t("Learn More")}
        </a>
      </div>
      <div className="flex flex-col items-center max-w-[800px] text-center gap-2 mt-20">
        <div className="mb-2 font-semibold font-title">
          {t("...or verify the authenticity of a document signed with Portant")}
        </div>
        <button className="btn btn-primary bg-white text-black w-60" onClick={() => setShowVerifyUploadModal(true)}>Upload</button>
      </div>
      <VerifyUploadModal visible={showVerifyUploadModal} closeModal={() => setShowVerifyUploadModal(false)} />
    </div>
  )
}

export default AppLanding;
